import React, { Component } from "react";
import loading from "../loading.gif";
import styles from "../Styles/spinner.module.css";

export class Spinner extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img src={loading} alt="Loading...." />
      </div>
    );
  }
}

export default Spinner;
