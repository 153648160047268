import React, { Component } from "react";
import styles from "../Styles/navbar.module.css";
import { Link } from "react-router-dom";

export class Navbar extends Component {
  constructor() {
    super();

    this.state = {
      clicked: false,
    };
  }

  handleClick = () => {
    this.setState({
      clicked: !this.state.clicked,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.navbar}>
          <Link to="/">
            <h2 className={styles.logo}>LatestNews</h2>
          </Link>
          <ul
            className={
              this.state.clicked
                ? `${styles.nav_list} ${styles.active}`
                : styles.nav_list
            }
          >
            <li>
              <Link to="/">General</Link>
            </li>
            <li>
              <Link to="/arts">Arts</Link>
            </li>
            <li>
              <Link to="/science">Science</Link>
            </li>
            <li>
              <Link to="/world">World</Link>
            </li>
          </ul>
          <div className={styles.menu_icon} onClick={this.handleClick}>
            <i
              className={
                this.state.clicked ? "fa-solid fa-times" : "fa-solid fa-bars"
              }
            ></i>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
