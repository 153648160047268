import React, { Component } from "react";
import styles from "../Styles/newsitem.module.css";

export class NewsItem extends Component {
  render() {
    let { newsUrl, imageUrl, title, description } = this.props;
    return (
      <div className={styles.card}>
        <a href={newsUrl} target="_blank" rel="noreferrer">
          <div className={styles.img_box}>
            <img src={imageUrl} alt="" />
          </div>
          <h4>{title}</h4>
          <p>{description}</p>
        </a>
      </div>
    );
  }
}

export default NewsItem;
