import React, { Component } from "react";
import NewsItem from "./NewsItem";
import styles from "../Styles/newsbox.module.css";
import Spinner from "./Spinner";

export class NewsBox extends Component {
  constructor() {
    super();

    this.state = {
      news: [],
      loading: false,
    };
  }

  async componentDidMount() {
    let url = `https://api.nytimes.com/svc/topstories/v2/${this.props.category}.json?api-key=${process.env.REACT_APP_API_KEY}`;
    this.setState({ loading: true });
    let data = await fetch(url);
    let parsedData = await data.json();
    this.setState({
      news: parsedData.results,
      loading: false,
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <h1>Top Headlines</h1>
        {this.state.loading && <Spinner />}
        <div className={styles.cards_container}>
          {this.state.news
            .filter((elem) => {
              return (
                elem.item_type !== "Promo" &&
                elem.item_type !== "EmbeddedInteractive" &&
                elem.multimedia !== null
              );
            })
            .map((elem) => {
              return (
                <NewsItem
                  title={elem.title}
                  description={elem.abstract}
                  imageUrl={elem.multimedia[1].url}
                  newsUrl={elem.url}
                  key={elem.url}
                />
              );
            })}
        </div>
      </div>
    );
  }
}

export default NewsBox;

/* .

return (
                <NewsItem
                  title={elem.title}
                  description={elem.abstract}
                  imageUrl={elem.multimedia[1].url}
                  newsUrl={elem.url}
                  key={elem.url}
                />
              );



              return elem.item_type !== "Promo" && elem.item_type !== "EmbeddedInteractive"? 
              <NewsItem
                title={elem.title}
                description={elem.abstract}
                imageUrl={elem.multimedia[1].url}
                newsUrl={elem.url}
                key={elem.url}
              />
*/
